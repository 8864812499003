import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import BlogPostGrid from "../components/Blog/BlogPostGrid";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  filterOutPostsFromOtherCategories,
  mapEdgesToNodes,
} from "../lib/helpers";
import BlogPostFilter from "../components/Blog/BlogPostFilter";
import EmptyBlogGrid from "../components/Blog/EmptyBlogGrid";

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    allSanityBlogCategories(sort: { fields: title, order: ASC }) {
      edges {
        node {
          _id
          title
        }
      }
    }
    blogs: allSanityBlogs(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const [currentCategory, setCurrentCategory] = useState(null);
  const postNodes =
    data &&
    data.blogs &&
    mapEdgesToNodes(data.blogs)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
      .filter((blogpost) =>
        filterOutPostsFromOtherCategories(blogpost, currentCategory)
      );

  const allBlogsOption = { _id: "all", title: "All Blogs" };
  const blogCategories =
    data &&
    data.allSanityBlogCategories &&
    mapEdgesToNodes(data.allSanityBlogCategories);
  blogCategories.unshift(allBlogsOption);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Tax & Accounting Blog | DeBlanc + Murphy | CPA Firm"
        description="Let's talk accounting and taxes. Read the DeBlanc + Murphy blog to build your knowledge and gain tips. Plus, sign up to receive articles in your inbox."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pb-16 pt-10 md:pb-20 md:pt-14">
        <div className="container">
          <header className="mb-14 md:mb-20">
            <h1>Our Blog: Let’s Talk Accounting & Taxes</h1>
          </header>
          <BlogPostFilter
            blogCategories={blogCategories}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
          />
          {postNodes && postNodes.length > 0 ? (
            <BlogPostGrid nodes={postNodes} />
          ) : (
            <EmptyBlogGrid />
          )}
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Page;
