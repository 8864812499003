import React, { useState } from "react";

function BlogPostFilter(props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((p) => !p);
  };

  const handleSelect = (category) => {
    props.setCurrentCategory(category);
    setIsOpen((p) => false);
  };

  return (
    <div className="relative mt-14 mb-[37px] flex w-full items-end justify-end align-bottom">
      <div className="ddcontainer w-[356px]">
        <div
          className="flex w-full cursor-pointer justify-between rounded-lg bg-[#343434] px-4 py-3 text-[#ACAFAF]"
          onClick={handleOpen}
        >
          <div className="flex">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMTE3MTkgMi4xNDg0NEMxLjM5MDYyIDEuNjAxNTYgMS45Mzc1IDEuMjUgMi41NjI1IDEuMjVIMTkuNDM3NUMyMC4wMjM0IDEuMjUgMjAuNTcwMyAxLjYwMTU2IDIwLjg0MzggMi4xNDg0NEMyMS4wNzgxIDIuNjk1MzEgMjEgMy4zNTkzOCAyMC42MDk0IDMuODI4MTJMMTMuNSAxMi41MzkxVjE3LjVDMTMuNSAxOC4wMDc4IDEzLjIyNjYgMTguNDM3NSAxMi43OTY5IDE4LjYzMjhDMTIuMzY3MiAxOC44MjgxIDExLjg1OTQgMTguNzg5MSAxMS40Njg4IDE4LjUxNTZMOC45Njg3NSAxNi42NDA2QzguNjU2MjUgMTYuNDA2MiA4LjUgMTYuMDU0NyA4LjUgMTUuNjI1VjEyLjUzOTFMMS4zNTE1NiAzLjgyODEyQzAuOTYwOTM4IDMuMzU5MzggMC44ODI4MTIgMi42OTUzMSAxLjExNzE5IDIuMTQ4NDRaIiBmaWxsPSIjODZCQzI1Ij48L3BhdGg+Cjwvc3ZnPg=="
              width={24}
              height={24}
              className="mr-3"
            />
            <div>
              {props.currentCategory
                ? props.currentCategory.title
                : "Filter By Category"}
            </div>
          </div>
          <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNTMxMjUgNC41NzAzMUM5Ljc2NTYyIDQuMzM1OTQgMTAuMTk1MyA0LjMzNTk0IDEwLjQyOTcgNC41NzAzMUwxNy45Mjk3IDEyLjA3MDNDMTguMTY0MSAxMi4zMDQ3IDE4LjE2NDEgMTIuNzM0NCAxNy45Mjk3IDEyLjk2ODhDMTcuNjk1MyAxMy4yMDMxIDE3LjI2NTYgMTMuMjAzMSAxNy4wMzEyIDEyLjk2ODhMMTAgNS44OTg0NEwyLjkyOTY5IDEyLjk2ODhDMi42OTUzMSAxMy4yMDMxIDIuMjY1NjIgMTMuMjAzMSAyLjAzMTI1IDEyLjk2ODhDMS43OTY4OCAxMi43MzQ0IDEuNzk2ODggMTIuMzA0NyAyLjAzMTI1IDEyLjA3MDNMOS41MzEyNSA0LjU3MDMxWiIgZmlsbD0iIzg2QkMyNSI+PC9wYXRoPgo8L3N2Zz4="
            className={`${isOpen ? "" : "rotate-180"}`}
          />
        </div>
        {isOpen && (
          <div className="absolute z-30 flex w-[356px] flex-col gap-y-6 bg-[#343434] px-[46px] py-[43px] transition duration-500">
            {props.blogCategories.map((blogCategory) => (
              <div
                value={blogCategory.id}
                key={blogCategory.id}
                className="cursor-pointer uppercase hover:text-[#86BC25]"
                onClick={() => handleSelect(blogCategory)}
              >
                {blogCategory.title}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default BlogPostFilter;
