import React from "react";

function EmptyBlogGrid() {
  return (
    <div>
      We couldn't find content with those tags. Try with another please.
    </div>
  );
}

export default EmptyBlogGrid;
